// extracted by mini-css-extract-plugin
export var swiperPagination = "swiperPagination-1hU";
export var swiperPaginationBullet = "swiperPaginationBullet-3sV";
export var swiperPaginationBulletBrands = "swiperPaginationBulletBrands-2V0";
export var swiperPaginationBulletActive = "swiperPaginationBulletActive-1Jx";
export var carousel_item_brand = "carousel_item_brand-3DJ";
export var iframe_wrapper = "iframe_wrapper-2oQ";
export var theme_brands = "theme_brands-NFZ";
export var theme_default = "theme_default-gbU";
export var arrows = "arrows-2t5";
export var container = "container-2hg";
export var leftAlignTitle = "leftAlignTitle-1LW";