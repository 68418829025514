import React from "react"
import PropTypes from "prop-types"
import SwiperCore, { A11y, Navigation, Autoplay, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import classNames from "classnames"

import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"

import SVG from "@components/svg"

import * as styles from "./styles.module.scss"

SwiperCore.use([Navigation, A11y, Autoplay, Pagination])

const Carousel = ({ children, theme = "default", title, leftAlignTitle }) => {
  let carouselProps

  if (theme === "default") {
    carouselProps = {
      navigation: { nextEl: "#next-slide", prevEl: "#prev-slide" }
    }
  }

  if (theme === "brands") {
    carouselProps = {
      breakpoints: {
        320: {
          slidesPerGroup: 2,
          slidesPerView: 2
        },
        720: {
          slidesPerGroup: 3,
          slidesPerView: 3
        },
        960: {
          slidesPerGroup: 4,
          slidesPerView: 4
        }
      },
      spaceBetween: 30
    }
  }

  const classes = classNames({
    [styles[`theme_${theme}`]]: theme
  })

  const container = classNames(styles.container, {
    [styles.leftAlignTitle]: leftAlignTitle
  })

  return (
    <div className={container}>
      {title && <h3>{title}</h3>}
      <div className={classes}>
        {theme === "default" && (
          <div className={styles.arrows}>
            <button id="prev-slide">
              <SVG name="angle-left" />
            </button>
            <button id="next-slide">
              <SVG name="angle-right" />
            </button>
          </div>
        )}
        <Swiper
          a11y
          loop={true}
          loopAdditionalSlides={children.length}
          allowTouchMove={true}
          autoplay={{ delay: 7500 }}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={0}
          pagination={{
            bulletActiveClass: styles.swiperPaginationBulletActive,
            bulletClass: styles.swiperPaginationBullet,
            bulletElement: "div",
            clickable: true,
            el: `#${theme === "brands" ? "brandsSwiper" : "defaultSwiper"}`
          }}
          {...carouselProps}
        >
          {children.map((slide, idx) => {
            const slideClasses = classNames({
              [styles.carousel_item_brand]: theme === "brands",
              [styles.iframe_wrapper]: slide.type === "iframe" || slide.props.isiframe
            })
            return (
              <SwiperSlide key={idx}>
                <div className={slideClasses}>{slide}</div>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div
          id={theme === "brands" ? "brandsSwiper" : "defaultSwiper"}
          className={styles.swiperPagination}
        ></div>
      </div>
    </div>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  leftAlignTitle: PropTypes.bool,
  theme: PropTypes.oneOf(["default", "brands"]),
  title: PropTypes.string
}

Carousel.defaultProps = { leftAlignTitle: false, theme: "default" }

export default Carousel
